<template>
   <div class="home">
   <!-- selbst hinzugefügt -->
     <SideBar /> 

    <Dashboard />
  </div> 
</template>

<script>
import Dashboard from '@/components/Dashboard.vue'
import SideBar from "@/components/SideBar.vue";

  export default {
    name: 'Home',

    components: {
      Dashboard,
      SideBar
    },
    
  }
</script>
<style scoped>

</style>
