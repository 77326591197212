<template>
  <v-container fluid class="mt-1">
     <v-row>
        <v-col cols="6" sm="3" lg="4" md="3" class="d-flex "> 
        <!-- Wetter -->
                <v-card dark class="text-center flex-grow-1">
                    <div class="text-center justify-center align-center">
                        <Search @daily="getDaily" @seven="getSeven" />
                        <CardsDashboard :daily="dailyData" :seven="sevenData"/> 
                    </div>
                </v-card>
        </v-col>
        <!-- Feuchtigkeit -->
        <v-col cols="6" sm="3" lg="4" md="3" class="d-flex">
            <v-card dark elevation-5 align="center" class="text-center flex-grow-1">
                  <v-progress-circular
                    :rotate="90"
                    :value="feuchtigkeitswert/671*100"
                    color="white"
                    size="150"
                    :width="16"
                    class="mt-7"
                    >
                    <div>
                        {{ feuchtigkeitswert }} 
                    </div>
                </v-progress-circular>
                <v-spacer></v-spacer>
                <div  justify-center align="center" class="mt-4 text-title">
                    Feuchtigkeit der Erde: {{ (feuchtigkeitswert/671*100).toFixed(1) }} %
                </div>
                <div  justify-center align="center" class="mt-4">
                      Zustand: {{feuchtigkeitszustand}}
                </div>
            </v-card>
        </v-col>
        <!-- Pflanze -->
        <v-col cols="12" sm="3" lg="4" md="3" class="d-flex">
            <v-card dark elevation-5 align="center" class="text-center flex-grow-1">
             <div class="text-center justify-center align-center">
              <v-col cols="12" lg="">
              
                    <v-select 
                    
                        v-model="selected"
                        
                        :items="itemsBewässerung"
                        item-text="stand"
                        label="Bewässerung"
                        required>
                          <template #item="data">
                            <v-tooltip bottom>
                              <template #activator="{ on, attrs }">
                                <v-layout  v-on="on" v-bind="attrs">
                                  <v-list-item-content >
                                    <v-list-item-title>{{data.item}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-layout>
                              </template>
                              <span> {{ `${items} tooltip` }}</span>
                            </v-tooltip>
                          </template>
                        </v-select>

                        <v-input>Bewässerung: {{ selected }}</v-input>
                        <v-select 
                        v-model="selectedPflanze"
                        :items="itemsPflanze"
                        label="Pflanzenart"
                        required>
                        </v-select>
                        <v-input>Pflanzenart: {{ selectedPflanze }}</v-input>
                        <v-btn   @click="save();" > speichern </v-btn> 
                  </v-col>
                </div>
            </v-card>
        </v-col>
        <!-- PopUp -->
        <v-dialog v-model="pop" width="60vw" overlay-opacity="0.8"
                            :fullscreen="$vuetify.breakpoint.smAndDown">
                            <v-card>
                                <v-toolbar dark flat>
                                    <v-toolbar-title>Pflanze ändern</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon dark @click="pop = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-row no-gutters style="overflow: hidden">
                                    <!-- Plant Picture/Grid/Carousel -->
                                    <v-col cols="12" sm="6">
                                        <v-expand-transition>
                                            <div v-show="!grid">
                                                <v-carousel hide-delimiters v-model="plantIxPop" height="auto"
                                                    show-arrows-on-hover>
                                                    <v-carousel-item eager v-for="p in plants" min-width="300"
                                                        :key="p.name" :aspect-ratio="1/1" :src="'./img/' + p.img">
                                                    </v-carousel-item>
                                                </v-carousel>
                                            </div>
                                        </v-expand-transition>
                                        <v-expand-transition>
                                            <div v-show="grid">
                                                <!-- Mobile -->
                                                <v-row class="ma-0 hidden-md-and-up"
                                                    style="height: 100vw; overflow: auto">
                                                    <v-col v-for="(p, i) in plants" :key="i" class="d-flex child-flex"
                                                        cols="6" md="4">
                                                        <v-hover v-slot="{ hover }">
                                                            <v-card
                                                                :class="{'elevation-3': hover || i==plantIxPop, 'elevation-0': !hover && i!=plantIxPop}"
                                                                class="pa-3" @click="plantIxPop=i">
                                                                <v-img :src="'./img/' + p.img" aspect-ratio="1">
                                                                </v-img>
                                                            </v-card>
                                                        </v-hover>
                                                    </v-col>
                                                </v-row>
                                                <!-- Desktop -->
                                                <v-row class="ma-0 hidden-sm-and-down"
                                                    style="height: 30vw; overflow: auto">
                                                    <v-col v-for="(p, i) in plants" :key="i" class="d-flex child-flex"
                                                        cols="6" md="4">
                                                        <v-hover v-slot="{ hover }">
                                                            <v-card
                                                                :class="{'elevation-3': hover || i==plantIxPop, 'elevation-0': !hover && i!=plantIxPop}"
                                                                class="pa-3" @click="plantIxPop=i">
                                                                <v-img :src="'./img/' + p.img" aspect-ratio="1">
                                                                </v-img>
                                                            </v-card>
                                                        </v-hover>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-expand-transition>
                                    </v-col>
                                    <!-- Plant Description -->
                                    <!-- Plant -->
                                    <!-- Desktop -->
                                    <v-col cols="12" sm="6" class="hidden-md-and-down" style="position: relative">
                                        <v-container style="min-height: 40%" class="pa-6">
                                            <h2 class="text-md-h5">{{plants[plantIxPop].name}}</h2>
                                            <v-form>
                                                <v-select 
                                                    v-model="selected"
                                                    :items="itemsBewässerung"
                                                    item-text="stand"
                                                    label="Bewässerung"
                                                    required>
                                                  </v-select>
                                                  <v-input>Bewässerung: {{ selected }}</v-input>
                                                  <v-select 
                                                    v-model="selectedPflanze"
                                                    :items="itemsPflanze"
                                                    label="Pflanzenart"
                                                    required>
                                                  </v-select>
                                                  <v-input>Pflanzenart: {{ selectedPflanze }}</v-input>

                                                  <v-btn absolute right @click="save();" > speichern </v-btn>
                                                  <br>
                                            </v-form>
                                        </v-container>
                                    </v-col>
                                    <!-- Mobile -->
                                    <v-col cols="12" sm="6" class="hidden-lg-and-up plantInfoList pa-4">
                                        <h2 class="text-h5 pb-2">
                                            {{plants[plantIxPop].name}}
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon class="float-right" v-bind="attrs" v-on="on">
                                                        mdi-information
                                                    </v-icon>
                                                </template>
                                            </v-tooltip>
                                        </h2>
                                        <v-divider></v-divider>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-icon large x-large @click="grid = !grid">{{!grid ? "mdi-view-grid-plus" :
                                        "mdi-border-all-variant"
                                        }}</v-icon>
                                    <v-spacer></v-spacer>
                                    <v-btn color="default" text @click="plantIx = plantIxPop; pop = false">
                                        Change
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
    </v-row>
    <!-- Wasserstand -->
    <v-row class="mt-1">
    <v-col cols="12" sm="4" lg="7" md="7">
        <v-card dark class="text-center">
            <v-card-title align="center">
                Wasserstand
            </v-card-title>
            <v-sparkline 
            :labels="labels"
            :value="sparklineData" 
            padding="20"
            label-size="6"
            color="#52BE80"
            :gradient="['#27AE60','#D4EFDF']" 
            :line-width="2"
            :stroke-linecap="'round'"
            smooth>
            <template v-slot:label="item">
                {{ item.value }}
            </template>
            </v-sparkline>
            <v-card-actions class="py-3 justify-center">
                <v-btn color="green" to="/wasserstand">Details</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
    <v-col cols="6" sm="4" lg="5" md="5" class="d-flex">
        <v-card dark class="text-center flex-grow-1" >
            <v-card-title align="center" >
                Überwachung
                <v-spacer></v-spacer> 
                
                <v-badge
                color="red"
                :content="countErrors"
                right
                >
                    <v-icon @click="countErrors = 0">mdi-bell</v-icon>
                </v-badge>
            </v-card-title>

            <v-simple-table
            fixed-header
            >
            <template v-slot:default >
            <thead>
                <tr>
                <th class="text-left">
                    Datum
                </th>
                <th class="text-left">
                    Beschreibung
                </th>
                </tr>
            </thead>
            <tbody class="text-left">
                <tr v-for="item in logDaten" :key="item.wert">
                <td>{{ item.Beschreibung }}</td>
                <td>{{ item.Zeitstempel }}</td>
                </tr>
            </tbody>
            </template>
            </v-simple-table>
        </v-card>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardsDashboard from "@/components/cardsDashboard.vue";
import Search from "@/components/search.vue";
import EventBus from '../EventBus';

export default {
  name: "Dashboard",
  props: ['daily', 'seven', 'select', 'datenbank'],
  components: {
    CardsDashboard, 
    Search,
  },
  
  data: () => ({
    items: [
      'niedrig: die Erde kann hin und wieder für einige Zeit trocken sein', 
      'mittel: die Erde ist leicht feucht', 
      'hoch: die Erde ist nie trocken'
      ],

    standort: 'Standort 1',
    datenbankName: 'smartgarden',
    sevenData: [],
    dailyData: [],
    darkMode: false,
    showCard: true,

    answer: {},  
    timer: "",  
    //
    time: '23:16',
    showFahrenheit: false,
    temp: '22',

    feuchtigkeitswert: 0,
    feuchtigkeitszustand: '',

    sparklineData: [
  
    ],
    labels: [
      'FR',
      'SA',
      'SO',
      'MO',
      'DI',
      'MI',
      'DO',
    ],
    
    selected: null,
    itemsBewässerung: [
      'niedrig',
      'mittel',
      'hoch',
    ],
    // itemsBewässerung: [
    //   {wert: 'niedrig', tooltip: 'die Erde kann hin und wieder für einige Zeit trocken sein'},
    //   {wert: 'mittel', tooltip: 'die Erde ist leicht feucht'},
    //   {wert: 'hoch', tooltip: 'die Erde ist nie trocken'},

    // ],
    selectedPflanze: null,
    itemsPflanze: [
      'Tulpe',
      'Rose',
      'Sonnenblume',
      'Minze',
    ],
    plants: [],
    plantIx: 0,
    pop: false,
    plantIxPop: 0,
    grid: false,

    logDaten: [
        {
            Beschreibung: "",
            Zeitstempel: '',
        },   
    ],
    countErrors: 0,
  }),
  created() {
    //
    const getStandort = function(ort) {
        this.standort = ort;
        this.$cookies.set('ort', this.standort);
        console.log("Set cookie "+ this.standort);
        if(this.standort == 'Standort 2')
        {
            this.datenbankName = 'smartgarden-2'
            console.log(this.datenbankName + ' andere Datenbank');
            //console.log('andere Datenbank')
        }
        else
        {
            this.datenbankName = 'smartgarden'
            console.log(this.datenbankName + ' ursprung Datenbank');
            //console.log('ursprung datenbank');
        }
        //console.log("Nachher "+this.datenbankName);
    }
    EventBus.$on('select', getStandort);

    // Get data from server... sample here
    const weatherData = {
      location: {
        city: "Wien",
        country: "Österreich",
      },
    };
    const plants = [
      {
        name: "Lavendel",
        img: "lavendel2.jpeg",
        light: "sonnig",
        ground: "eher nährstoffarmem, durchlässigem Boden",
        info: [
          "Wissenschaftlicher Name: Lavandula angustifolia",
          "Familie: Lippenblütler (Lamiaceae)",
          "Nutzung: Heilpflanze, Zierpflanze",
        ],
      },

    ];
    this.forecast = weatherData.forecast;
    this.location = weatherData.location;
    this.plants = plants;
  },
  methods: {
    getDaily(data) {
      this.dailyData = data
    },

    getSeven(data) {
      this.sevenData = data
    },
    save(){    
      this.get();            
      this.axios.post('https://smartgarden.htl.jetzt/Diplomarbeit/db.php', {
          request: 'Pflanzenart',
          dbname: this.datenbankName,
          sel: this.selected,
          selP: this.selectedPflanze,
      })
      .then(function (response) {
          console.log(response);
          if(response.data[0].status==1){
              alert('Data saved successfully');
          } 
      })
      .catch(function (error) {
          console.log(error);
      });                  
    },
    getFeuchtigkeitswert (){
        var self = this;
        this.get();
        this.axios.post('https://smartgarden.htl.jetzt/Diplomarbeit/db.php', {
            request: 'FeuchtigkeitswertKreis',
            dbname: this.datenbankName
          })
          .then(function (value) {
              self.feuchtigkeitswert = value.data.Feuchtigkeitswert;
              console.log(self.feuchtigkeitswert);
          })
          .catch(function (error) {
            console.log(error);
         });
    },
    getFeuchtigkeitsZustand (){
        var self = this;
        this.get();
        this.axios.post('https://smartgarden.htl.jetzt/Diplomarbeit/db.php', {
            request: 'feuchtigkeitsZustand',
            dbname: this.datenbankName
          })
          .then(function (value) {
              //console.log(value.data.Zustand);
              self.feuchtigkeitszustand = value.data.Zustand;
              //console.log(self.feuchtigkeitszustand);
                
          })
          .catch(function (error) {
            console.log(error);
         });
    },
    getWasserstandsDaten (){
        var self = this;
        this.get();
        this.axios.post('https://smartgarden.htl.jetzt/Diplomarbeit/db.php', {
            request: 'WasserstandDiagramm',
            dbname: this.datenbankName
           })
           .then(function (daten) {
               const ka = daten.data;
               const array = ka.map((i) => Number(i));
               self.sparklineData = array;
           })
           .catch(function (error) {
             console.log(error);
          });
     },
     getError() {
        var self = this;
        console.log('Methode 7 '+this.datenbankName);
        this.axios.post('https://smartgarden.htl.jetzt/Diplomarbeit/db.php', {
            request: 7,
            dbname: this.datenbankName
          })
          .then(function (daten) {
                const  ka = JSON.parse((JSON.stringify(daten.data)));
                //console.log(ka);
                self.logDaten = ka;
                // console.log(self.logDaten = ka);
                self.countErrors = ka.length;
          })
          .catch(function (error) {
            console.log(error);
         });
    },
    get() {
      
        if (this.$cookies.isKey('ort')) {
          this.standort= this.$cookies.get('ort');
          if (this.$cookies.get('ort') == "Standort 1") {
              this.datenbankName = 'smartgarden';
              console.log('Get')
          }
          else {
              this.datenbankName = 'smartgarden-2';
              console.log('Get 2')

            }
          }

         //console.log("Ort ist "+this.$cookies.get('ort'));
       
        //console.log('3 hoppa '+ this.datenbankName);
    }
  },
  computed: {
    mini() {
      return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
    },
    
  },
  mounted() {
    // Call the API the first time
    this.get();
    this.getFeuchtigkeitswert();
    this.getFeuchtigkeitsZustand();
    this.getWasserstandsDaten();
    this.getError();
    // Then call the API every minute
    this.setIntervalId = setInterval(this.getFeuchtigkeitswert, 60000/6)
    this.setIntervalId = setInterval(this.getFeuchtigkeitsZustand, 60000/6)
    this.setIntervalId = setInterval(this.getWasserstandsDaten, 60000/6)
    this.setIntervalId = setInterval(this.getError, 60000/6)

    var currentDate = new Date();
    var nextWeek = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    var days = []
    while (currentDate <= nextWeek) {
      days.push(new Date(currentDate).getDay());
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.daysIndex = days.slice(1);
  },
  watch:{
  seven:function(){
     for (let i = 0; i < this.daysIndex.length; i++) {
       this.seven.list[i].day = this.days[this.daysIndex[i]]
    }
  }
}
};
</script>
<style>

</style>
