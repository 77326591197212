<template>
  <div class="container">
    <div class="">
      <h5>aktuelles Wetter</h5>
      <div class="">
        <div class="">
          <h5>{{ daily.name }}</h5>

          <div class="daily">
            <img
              :src="require(`../assets/img/weater_elements/${weathers[daily.weather[0].main]}.svg`)"
              width="120px"
              alt
            /> 
            <p>{{ parseInt(daily.main.temp) }}°</p>
          </div>

          <h5>{{ daily.weather[0].main }}</h5>
        </div> 

   
      </div>
    </div>
   
  </div>
</template>


<script>
export default {
  name: 'Cards',
  props: ['daily', 'seven'],
  data() {
    return {
      
      darkMode: false,
      weathers: {
        Snow: "snowy",
        Clouds: "bewölkt",
        Rain: "rainy",
        Clear: "sunny",
        Thunderstorm: "thunder",
      },
      days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      daysIndex: [],
    };
  },
  mounted() {
    var currentDate = new Date();
    var nextWeek = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    var days = []
    while (currentDate <= nextWeek) {
      days.push(new Date(currentDate).getDay());
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.daysIndex = days.slice(1);
  },
watch:{
  seven:function(){
     for (let i = 0; i < this.daysIndex.length; i++) {
       this.seven.list[i].day = this.days[this.daysIndex[i]]
    }
  }
}
}

</script>


<style>
.daily {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  font-size: 38px;
}
</style>
 