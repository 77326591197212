<template >
   <div class="login-page">
      <transition name="fade">
         <div class="wallpaper-login"></div>
      </transition>
      <div class="container">
         <div class="row">
          <div class="col-lg-6 col-md-5 col-sm-8 mx-auto">
            <v-alert 
                type="success" 
                text
                v-model="showDismissibleAlert">
                erfolgreich eingeloggt
            </v-alert>
            <v-alert 
                dismissible
                color="red" 
                text
                v-model="showDismissibleAlert2">
                Username oder Passwort falsch
            </v-alert>
            <v-alert 
                dismissible
                color="red" 
                text
                v-model="showDismissibleAlert3">
                bitte fülle die Felder aus
            </v-alert>
              <div class="card login">
                <h1>Login </h1>
                 <v-form v-model="valid" lazy-validation id="myform"> 
                    <v-col cols="12" lg="">
                        <v-text-field 
                            v-model="loginName" 
                            prepend-icon="mdi-account" 
                            label="username" 
                            :rules="UsernameRules"
                            name="input-10-1"
                            >
                            <!-- required -->
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="">
                        <v-text-field 
                            v-model="loginPassword"  
                            prepend-icon="mdi-lock" 
                            :append-icon="show1?'eye':'eye-off'" 
                            :rules="[rules.required, rules.min]" 
                            :type="show1 ? 'text' : 'password'" 
                            name="input-10-1" 
                            label="Password" 
                            hint="At least 8 characters" 
                            counter 
                            @click:append="show1 = !show1"
                            >
                        </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <!-- <v-col class="d-flex justify-content-center" cols="12" sm="3" xsm="12">
                        <v-btn x-large block  color="success" @click="save();" > Login </v-btn>
                    </v-col> -->
                    <v-col cols="12" lg="">
                      <div class="d-flex justify-content-center " cols="12" lg="">
                              <v-btn block class="mb-6 mt-6 white--text" color="#86c276" id="login" @click.prevent="save" v-if="!isLoggingIn">
                              Login 
                              </v-btn>
                              <!-- <v-btn class="w-25" color="#86c276" disabled @click.prevent="save" v-if="isLoggingIn"></v-btn> -->
                      </div>
                    </v-col>
                  </v-form>
              </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
  data: () => ({
    
    isLoggingIn: false,
    showDismissibleAlert: false,
    showDismissibleAlert2: false,
    showDismissibleAlert3: false,

    datenbankName: 'smartgarden',

    valid: true,
    
    name: "",
    password: "",
    //verify: "",
    loginPassword: "",
    loginName: "",

     UsernameRules: [
       v => !!v || "Required",
       //v => /.+@.+\..+/.test(v) || "E-mail must be valid"
     ],
    show1: false,
    rules: {
      required: value => !!value || "Required.",
      min: v => (v && v.length >= 8) || "Min 8 characters"
    },
  }),
  methods: {
    save: function(){
      var self = this;
      if(this.loginName != '' && this.loginPassword != '' ){
        this.axios.post('https://smartgarden.htl.jetzt/Diplomarbeit/db.php', {
          request: 1,
            accP: this.loginPassword,
            accN: this.loginName,
            dbname: this.datenbankName
        })
        .then(function (erg) {
          console.log(erg);
          
          if(erg.data[0].status==1){
            //alert('einloggen');

            self.showDismissibleAlert=true

            document.getElementById('myform').reset();
            setTimeout(() => {
            self.$router.replace({name: 'Home'})
            }, 1000);

          }
          else{
            //alert('nein');
            //document.getElementById('myform').reset();
            self.showDismissibleAlert2 = true
            setTimeout(() => {
            self.showDismissibleAlert2=false
            }, 5000);


          }
        })
        .catch(function (error) {
            console.log(error);
        }); 
      }
      else {
        //alert('Please enter username & password');
        this.showDismissibleAlert3=true
        setTimeout(() => {
          this.showDismissibleAlert3=false
        }, 5000);
        
      }
    },
  },
   mounted() {

  },
  created() {
      
  },
};
</script>

<style scoped lang="scss">
.card {
   padding: 15px;
   background-color: white;
   opacity: 0.8;
}
.login-page {
   align-items: center;
   display: flex;
   height: 100vh;

   .wallpaper-login {
      background: url('../assets/garten.jpg')
         no-repeat center center;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
   }
   h1  {
      margin-bottom: 1.5rem;
      color: #86c276;
   }
}
</style>