<template>
    <nav>
        <v-app-bar app dark class="line" height="55px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-app-bar-nav-icon @click.stop="sidebarMenu = !sidebarMenu"></v-app-bar-nav-icon>
            <v-toolbar-title>Dein SmartGarden</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-row justify="end" class="mt-3 mb-n4 mr-0" >
                <v-col cols="4" lg="5" md="4"> 
                    <v-combobox
                    v-model="select"
                    :items="orte"
                    label="Standort"
                    solo
                    dense
                    color="success"
                    @change="getStandort()"
                    >
                    </v-combobox>
                </v-col>
            </v-row>
            <v-btn to="/">
                <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>
        
        <!-- Nav -->
        <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndUp" 
        v-model="sidebarMenu"
        dark app
        src="../assets/garten.jpg"
        floating
        :permanent="sidebarMenu"
        mini-variant
        mini-variant-width="160">
            <v-list>
                <v-list-item-group color="white">
                    <v-list-item class="mb-4" to="/home">
                        <v-list-item-content align="center" >
                            <v-icon align="center">
                                mdi-home-outline
                            </v-icon>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                <v-list-item-group v-model="selectedItem" color="white">
                    <v-list-item v-for="(item, i) in items" :key="i" :to="item.link">
                        <v-list-item-content align="center">
                            <v-icon v-text="item.icons" class="mb-3"  >
                            </v-icon>
                            <v-list-item-subtitle v-text="item.text" align="center" class="caption" >
                            </v-list-item-subtitle>
                            
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        
        <v-bottom-navigation 
            v-model="value"
            :background-color="color"
            src="../assets/garten.jpg"
            dark
            shift
            fixed
            v-if="$vuetify.breakpoint.smAndDown" 
        >
        <v-btn to="/user">
            <span>User</span>
            <v-icon>mdi-account-outline</v-icon>
        </v-btn>
        <v-btn to="/home">
            <span>Home</span>
            <v-icon>mdi-home-outline</v-icon>
        </v-btn>
        <v-btn to="/wasserstand">
            <span>Wasserstand</span>
            <v-icon>mdi-water</v-icon>
        </v-btn>
        <v-btn to="/wetter">
            <span>Wetter</span>
            <v-icon>mdi-cloud</v-icon>
            <!-- <v-icon>mdi-flower-tulip-outline</v-icon> -->
        </v-btn>
        
  </v-bottom-navigation>
</nav>
</template>

<script>
import EventBus from '../EventBus';

export default {
  data: () => ({
    drawer: null,
    sidebarMenu: true,
    selectedItem: 0,
    items: [
      { text: "Feuchtigkeit", icons: "mdi-watering-can", link: "/feuchtigkeit" },
      { text: "Wasserstand", icons: "mdi-water", link: "/wasserstand" },
      { text: "Wetter", icons: "mdi-cloud", link: "/wetter" },
      { text: "User", icons: "mdi-account-outline", link: "/user" },
    //   { text: "Informationen", icons: "mdi-information", link: "/information" },

    ],
    datenbank: '',
    select: 'Standort 1',
        orte: [
          'Standort 1',
          'Standort 2',
        ],
    value: '',
  }),
    computed: {
      color () {
        switch (this.value) {
          case 0: return 'transparent'
          case 1: return 'transparent'
          case 2: return 'transparent'
          case 3: return 'transparent'
          default: return 'transparent'
        }
    }
    },
    methods: {
        getStandort (){   
            if(this.select == 'Standort 1')
            {
                this.datenbank = 'db.php';
                EventBus.$emit('select', this.select)
                EventBus.$emit('datenbank', this.datenbank)

            }
            else {
                this.datenbank = 'db2.php';
                EventBus.$emit('select', this.select)
                EventBus.$emit('datenbank', this.datenbank)
            }
        },
    },
    mounted() {

    }
};
</script>
<style scoped>
</style>
