<template>
    <div class="input">
          
    </div>
</template>
<script>
import axios from 'axios'

export default {

    data() {
        return {
            lat: "48.210033",
            lon: "16.363449",
            res: [],
        }
    },
    methods: {
    
        //With Geolocation
        async getGeolocation(data) {
            await axios(`https://api.openweathermap.org/data/2.5/forecast/daily?lat=${data.coords.latitude}&lon=${data.coords.longitude}&units=metric&appid=20571ab45c74dc2a1897b60c5b8047a1`).then((res) => {
                this.$emit('seven', res.data)
                this.res = res.data;
                //console.log(this.res)

            });

            await axios(`https://api.openweathermap.org/data/2.5/weather?lat=${data.coords.latitude}&lon=${data.coords.longitude}&units=metric&appid=20571ab45c74dc2a1897b60c5b8047a1`)
                .then((res) => {
                    this.$emit('daily', res.data)
                    this.res = res.data;
                    //console.log(res.data)

                })

            this.$emit('showCards');

        },

        // Get User Location 
        getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(data => {
                    this.getGeolocation(data);
                });
            }
            else {
                console.log("Geolocation is not supported by this browser.");
            }
        },
    },
    mounted() {
        // this.getGeolocation();
        this.getLocation();
    },
}
</script>


<style>

</style>