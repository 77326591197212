<template>
  <v-app :style="{background: $vuetify.theme.themes.dark.background}">
  <!-- selbst entfernt -->
    <!-- <SideBar />  -->
    <v-main>
          <router-view />
      <!-- selbst hinzugefügt -->
      <!-- <LogIn />  -->
    </v-main>
  </v-app>
</template>

<script>
// import SideBar from "./components/SideBar";
// import LogIn from "./views/LogIn";
export default {
  name: "App",

  components: {
    // SideBar,
    // LogIn
  },
  computed:{
    
  },

  data: () => ({
    //
  }),
};
</script>
